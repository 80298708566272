import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';

const routes: Routes = [
  { path: '', redirectTo: 'login', pathMatch: 'full' },
  {
    path: 'inicio',
    loadChildren: () => import('./pages/inicio/inicio.module').then( m => m.InicioPageModule)
  },
  {
    path: 'toast',
    loadChildren: () => import('./pages/toast/toast.module').then( m => m.ToastPageModule)
  },
  {
    path: 'login',
    loadChildren: () => import('./login/login.module').then( m => m.LoginPageModule)
  },
  {
    path: 'home',
    loadChildren: () => import('./pages/home/home.module').then( m => m.HomePageModule)
  },
  {
    path: 'pedidos-acaminho',
    loadChildren: () => import('./pages/pedidos-acaminho/pedidos-acaminho.module').then( m => m.PedidosACaminhoPageModule)
  },
  {
    path: 'detalhes-dos-pedidos-acaminho',
    loadChildren: () => import('./pages/detalhes-dos-pedidos-acaminho/detalhes-dos-pedidos-acaminho.module').then( m => m.DetalhesDosPedidosACaminhoPageModule)
  },
  {
    path: 'detalhes-dos-pedidos-acaminho/:id/:id_do_deposito/:produto/:quantidade/:valor_unti/:total/:forma_de_pagamento/:debito_ou_credito/:troco/:nota_fiscal/:cpf_cnpj/:endereco/:cidade/:numero/:complemento/:ponto_de_referencia/:data/:hora/:telefone/:cep',
    loadChildren: () => import('./pages/detalhes-dos-pedidos-acaminho/detalhes-dos-pedidos-acaminho.module').then( m => m.DetalhesDosPedidosACaminhoPageModule)
  },
  {
    path: 'relatorios',
    loadChildren: () => import('./pages/relatorios/relatorios.module').then( m => m.RelatoriosPageModule)
  },
  {
    path: 'configuracoes',
    loadChildren: () => import('./pages/configuracoes/configuracoes.module').then( m => m.ConfiguracoesPageModule)
  },
  {
    path: 'configuracoes-de-busca',
    loadChildren: () => import('./pages/configuracoes-de-busca/configuracoes-de-busca.module').then( m => m.ConfiguracoesDeBuscaPageModule)
  },
  {
    path: 'home/:usuario/:senha/:idDoDeposito',
    loadChildren: () => import('./pages/home/home.module').then( m => m.HomePageModule)
  },
  {
    path: 'inicio/:idDoDeposito',
    loadChildren: () => import('./pages/inicio/inicio.module').then( m => m.InicioPageModule)
  },
  {
    path: 'pedidos-acaminho/:idDoDeposito',
    loadChildren: () => import('./pages/pedidos-acaminho/pedidos-acaminho.module').then( m => m.PedidosACaminhoPageModule)
  },
  {
    path: 'relatorios/:id_do_deposito',
    loadChildren: () => import('./pages/relatorios/relatorios.module').then( m => m.RelatoriosPageModule)
  },
  {
    path: 'relatori-gas',
    loadChildren: () => import('./pages/relatori-gas/relatori-gas.module').then( m => m.RelatoriGasPageModule)
  },
  {
    path: 'relatorio-agua',
    loadChildren: () => import('./pages/relatorio-agua/relatorio-agua.module').then( m => m.RelatorioAguaPageModule)
  },
  {
    path: 'historico',
    loadChildren: () => import('./pages/historico/historico.module').then( m => m.HistoricoPageModule)
  },
  {
    path: 'impressao-nota',
    loadChildren: () => import('./pages/impressao-nota/impressao-nota.module').then( m => m.ImpressaoNotaPageModule)
  },
  {
    path: 'impressao-nota/:id/:produto/:quantidade/:valor_unti/:total/:forma_de_pagamento/:debito_ou_credito/:troco/:cpf_cnpj/:endereco/:cidade/:numero/:complemento/:ponto_de_referencia/:data/:hora/:telefone/:cep/:destino_da_impressao',
    loadChildren: () => import('./pages/impressao-nota/impressao-nota.module').then( m => m.ImpressaoNotaPageModule)
  },
  {
    path: 'faturas-atrazadas',
    loadChildren: () => import('./pages/faturas-atrazadas/faturas-atrazadas.module').then( m => m.FaturasAtrazadasPageModule)
  },
  {
    path: 'faturamento',
    loadChildren: () => import('./pages/faturamento/faturamento.module').then( m => m.FaturamentoPageModule)
  },
  {
    path: 'detalhes-do-deposito',
    loadChildren: () => import('./pages/detalhes-do-deposito/detalhes-do-deposito.module').then( m => m.DetalhesDoDepositoPageModule)
  },
  {
    path: 'detalhes-do-deposito/:id_do_deposito',
    loadChildren: () => import('./pages/detalhes-do-deposito/detalhes-do-deposito.module').then( m => m.DetalhesDoDepositoPageModule)
  },
  {
    path: 'fatura',
    loadChildren: () => import('./pages/fatura/fatura.module').then( m => m.FaturaPageModule)
  }, 
  {
    path: 'relatori-gas/:id_do_deposito',
    loadChildren: () => import('./pages/relatori-gas/relatori-gas.module').then( m => m.RelatoriGasPageModule)
  },
  {
    path: 'relatorio-agua/:id_do_deposito',
    loadChildren: () => import('./pages/relatorio-agua/relatorio-agua.module').then( m => m.RelatorioAguaPageModule)
  },

];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules })
  ],
  exports: [RouterModule]
})
export class AppRoutingModule { }
